<!-- 商学院 -->
<template>
  <div>
    <div class="eg_hwef">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="好友运营" name="1"></el-tab-pane>
        <el-tab-pane label="优惠券运营" name="2"></el-tab-pane>
        <el-tab-pane label="商品运营" name="3"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="ef_bhygywef">
      <div class="gr_hgwef" v-for="(item, index) in videoList">
        <img
          :src="item.videoImgUrl"
          alt=""
          class="imgwf_ue"
          v-show="item.isShow"
        />
        <i
          v-show="item.isShow"
          class="el-icon-caret-right i-cds"
          @click="onVideo(item, index)"
        ></i>
        <div v-show="item.isShow" class="wf_hywf">{{ item.playNum }}</div>
        <video
          :src="item.videoUrl"
          controls="controls"
          :ref="`refVideo${index}`"
        ></video>
      </div>
    </div>
    <!-- <div class="eg_hgywf">
      <el-pagination background layout="prev, pager, next" :total="1000">
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      activeName: "1",
      videoList: [],
      ind: "",
      goodFriendList: [
        {
          businessSchoolContentId: 1,
          playNum: 135,
          title: "商家为啥要拉取好友到车世际平台呢？",
          videoImgUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-1.jpg",
          videoUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1_1.mp4",
          isShow: true,
        },
        {
          businessSchoolContentId: 2,
          playNum: 235,
          title: "怎么把好友从微信中拉到车世际APP中成为我的好友？",
          videoImgUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-2.jpg",
          videoUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-2.mp4",
          isShow: true,
        },
        {
          businessSchoolContentId: 3,
          playNum: 125,
          title: "作为普通用户，我往车世际APP拉好友，有什么价值呢？",
          videoImgUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-3.jpg",
          videoUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-3.mp4",
          isShow: true,
        },
        {
          businessSchoolContentId: 4,
          playNum: 113,
          title: "在哪里查看我的收益呢？",
          videoImgUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-4.jpg",
          videoUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-4.mp4",
          isShow: true,
        },
        {
          businessSchoolContentId: 5,
          playNum: 100,
          title: "车辆托管业务是怎么回事情？",
          videoImgUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-5.jpg",
          videoUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-5.mp4",
          isShow: true,
        },
        {
          businessSchoolContentId: 6,
          playNum: 201,
          title: "和好友怎么互动？",
          videoImgUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-6.jpg",
          videoUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-6.mp4",
          isShow: true,
        },
        {
          businessSchoolContentId: 7,
          playNum: 300,
          title: "优惠券的实际效果与价值？",
          videoImgUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-7.jpg",
          videoUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-7.mp4",
          isShow: true,
        },
      ],
      DiscountList: [
        {
          businessSchoolContentId: 8,
          playNum: 269,
          title: "推荐司机是怎么回事？",
          videoImgUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-8.jpg",
          videoUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-8.mp4",
          isShow: true,
        },
        {
          businessSchoolContentId: 9,
          playNum: 659,
          title: "优惠券制作需要注意一些什么？",
          videoImgUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-9.jpg",
          videoUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-9.mp4",
          isShow: true,
        },
        {
          businessSchoolContentId: 10,
          playNum: 548,
          title: "用优惠券做异业合作是怎么回事？",
          videoImgUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-10.jpg",
          videoUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-10.mp4",
          isShow: true,
        },
      ],
      commodityList: [
        {
          businessSchoolContentId: 11,
          isShow: true,
          playNum: 627,
          title: "如何设计规划自己在车世纪APP上的产品？",
          videoImgUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-11.jpg",
          videoUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-11.mp4",
        },
        {
          businessSchoolContentId: 12,
          isShow: true,
          playNum: 558,
          title: "如何让更多的人帮我推销我的产品？",
          videoImgUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-12.jpg",
          videoUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-12.mp4",
        },
        {
          businessSchoolContentId: 13,
          isShow: true,
          playNum: 516,
          title: "商家是应该多上产品好呢？还是少上产品好？",
          videoImgUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-13.jpg",
          videoUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-13.mp4",
        },
        {
          businessSchoolContentId: 14,
          isShow: true,
          playNum: 516,
          title: "啥是代运营？",
          videoImgUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-14.jpg",
          videoUrl:
            "https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/businessVideo/1-14.mp4",
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleClick(e) {
      this.videoList = [];
      this.ind = "";
      if (e.name == 1) {
        this.videoList = this.goodFriendList;
        this.videoList.map((e) => {
          e.isShow = true;
        });
      } else if (e.name == 2) {
        this.videoList = this.DiscountList;
        this.videoList.map((e) => {
          e.isShow = true;
        });
      } else {
        this.videoList = this.commodityList;
        this.videoList.map((e) => {
          e.isShow = true;
        });
      }
    },
    onVideo(e, i) {
      e.isShow = false;
      if (i == this.ind) {
        this.$refs[`refVideo${i}`][0].play();
      } else {
        this.$refs[`refVideo${i}`][0].play();
        if (this.ind) {
          this.$refs[`refVideo${this.ind}`][0].pause();
          this.videoList[this.ind].isShow = true;
        }
      }
      this.ind = i;
    },
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    if (this.activeName == 1) {
      this.videoList = this.goodFriendList;
    }
  },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.eg_hwef {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  border-bottom: 1px solid #d5d5d5;
}
.eg_hwef /deep/.el-tabs__header {
  margin: 0;
}
.eg_hwef /deep/.el-tabs__item.is-active {
  color: #ff6600;
}
.eg_hwef /deep/.el-tabs__active-bar {
  background-color: #ff6600;
}
.eg_hwef /deep/.el-tabs__item:hover {
  color: #ff6600;
  cursor: pointer;
}
.ef_bhygywef {
  width: 100%;
  padding: 53px 240px 0 240px;
  display: flex;
  flex-wrap: wrap;
}
.gr_hgwef {
  width: 21%;
  height: 423px;
  margin-bottom: 60px;
  margin-right: 60px;
  position: relative;
}
.gr_hgwef:nth-child(4n) {
  margin-right: 0px;
}
.gr_hgwef video {
  width: 100%;
  height: 423px;
}
.eg_hgywf {
  display: flex;
  justify-content: center;
}
.eg_hgywf
  /deep/.el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #ff6600;
  color: #fff;
}
.eg_hgywf
  /deep/.el-pagination.is-background
  .el-pager
  li:not(.disabled).active:hover {
  color: #fff;
}
.eg_hgywf /deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #ff6600;
}
.imgwf_ue {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 423px;
  z-index: 99;
}
.i-cds {
  position: absolute;
  left: 0;
  bottom: 5px;
  z-index: 100;
}
.gr_hgwef /deep/ .el-icon-caret-right:before {
  font-size: 25px;
  color: #ffff;
}
.wf_hywf {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 27px;
  color: #ffffff;
  position: absolute;
  bottom: 5px;
  left: 25px;
  z-index: 100;
}
</style>